import React, { useState, FormEvent } from "react";
import { useSelector, useDispatch } from "react-redux";

import { REACT_APP_SERVER_ADDRESS } from "../../config/client_const";

import { ServicesList } from "../../components/ServicesList/ServicesList";
import { selectBasket, emptyBasket } from "../../redux/slices/basketSlice";
import { setItemsToZero } from "../../redux/slices/servicesBucketSlice";

import stylesCart from "./Cart.module.css";
import { Link } from "react-router-dom";

import arrowLeft from '../../static/arrow_left.svg';

export const Cart = () => {
  const dispatch = useDispatch();

  const basket = useSelector(selectBasket);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [telegram, setTelegram] = useState(false);
  const [whatsapp, setWhatsapp] = useState(false);
  const [viber, setViber] = useState(false);

  const [orderIsSending, setOrderIsSending] = useState(false);
  const [isOrderSent, setIsOrderSent] = useState(false);
  const [isOrderError, setIsOrderError] = useState(false);

  // useEffect(() => {
  //   async function sendTestMail() {
  //     const mailInfo = await fetch("http://localhost:8080/api/send-test-mail", { method: "POST" });
  //     console.log(mailInfo.json());
  //   }

  //   sendTestMail();
  // }, [])

  async function sendOrder(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setOrderIsSending(true);

    const orderData = {
      name,
      phone,
      email,
      telegram,
      whatsapp,
      viber,
      basket,
    };

    try {
      await fetch(`${REACT_APP_SERVER_ADDRESS}/api/send-order-mail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(orderData),
      }).then(async (res) => await res.json());

      setIsOrderSent(true);

      setIsOrderSent(true);
      setName("");
      setPhone("");
      setEmail("");
      setTelegram(false);
      setWhatsapp(false);
      setViber(false);

      dispatch(emptyBasket());
      dispatch(setItemsToZero());
    } catch (error) {
      console.log(error);
      setIsOrderError(true);
    }

    setOrderIsSending(false);
  }

  return (
    <main>
      {/* <div className={stylesCart.topPart}>
        <div className={stylesCart.topPartContent}>
          <h1 className={stylesCart.topPartHeader}>
            <span className={stylesCart.topPartHeader}>
              Свяжитесь с нами по телефону
            </span>
            <br />
            <span className={stylesCart.topPartHeaderHighlighted}>
              <a href="tel:+381629337456">+381629337456</a>
            </span>
          </h1>
          <h3 className={stylesCart.topPartHeaderAdditional}>Мы есть в Telegram, Whatsapp, Viber</h3>
        </div>
      </div> */}

      <div className={stylesCart.order}>
        <div className={stylesCart.orderContent}>
          <Link to="/" className={stylesCart.linkBack}>
            <img alt="Left arrow" src={arrowLeft} />
            <p className={stylesCart.linkBackText}>Назад</p>
          </Link>
          <form className={stylesCart.orderForm} onSubmit={(e) => sendOrder(e)}>
            <div className={stylesCart.orderFormHeader}>
              <h3>Оставить заявку</h3>
            </div>
            <div>
              <div className={stylesCart.orderFormBodyRow}>
                <input
                  type="text"
                  placeholder="Имя"
                  className={stylesCart.orderFormInput}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className={stylesCart.orderFormBodyRow}>
                <input
                  type="phone"
                  placeholder="Телефон"
                  className={stylesCart.orderFormInput}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
              <div className={stylesCart.orderFormBodyRow}>
                <input
                  type="email"
                  placeholder="Email"
                  className={stylesCart.orderFormInput}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className={stylesCart.orderFormBodyRow}>
                <h4 className={stylesCart.orderFormBodyRowH4} style={{maxWidth: 600}}>
                  Выберите мессенджеры, в которых с вами можно связаться 
                  по вышеуказанному номеру телефона:
                </h4>
              </div>
              <div className={stylesCart.orderFormBodyRow}>
                <input
                  type="checkbox"
                  id="telegram"
                  className={stylesCart.orderFormCheckbox}
                  value={`${telegram}`}
                  onChange={() => setTelegram(!telegram)}
                />
                <p className={stylesCart.orderFormBodyRowText}>Telegram</p>
              </div>
              <div className={stylesCart.orderFormBodyRow}>
                <input
                  type="checkbox"
                  id="whatsapp"
                  className={stylesCart.orderFormCheckbox}
                  value={`${whatsapp}`}
                  onChange={() => setWhatsapp(!whatsapp)}
                />
                <p className={stylesCart.orderFormBodyRowText}>Whatsapp</p>
              </div>
              <div className={stylesCart.orderFormBodyRow}>
                <input
                  type="checkbox"
                  id="viber"
                  className={stylesCart.orderFormCheckbox}
                  value={`${viber}`}
                  onChange={() => setViber(!viber)}
                />
                <p className={stylesCart.orderFormBodyRowText}>Viber</p>
              </div>

              <div className={stylesCart.orderFormBodyRow}>
                <input
                  type="submit"
                  className={stylesCart.orderButton}
                  value={orderIsSending ? "Отправка..." : "Отправить"}
                />
                {isOrderSent && (
                  <p className={stylesCart.orderFormBodyRowText}>
                    Заказ отправлен. Мы свяжемся с вами в ближайшее время.
                  </p>
                )}
                {isOrderError && (
                  <p className={stylesCart.orderFormBodyRowText}>
                    Произошла ошибка. Попробуйте еще раз.
                  </p>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className={stylesCart.content}>
        <h3 className={stylesCart.topPartTotalPrice}>
          Итого:
          <span className={stylesCart.topPartTotalPriceHighlighted}>
          {" от "}{basket.totalPrice}€
          </span>
        </h3>
        <ServicesList services={basket.items} />
        {/* @ts-ignore */}
        {/* <SpringGrid className={services">
          {services.map((service) => (
            <ServiceCard service={service} />
          ))}
        </SpringGrid> */}
      </div>
    </main>
  );
};
