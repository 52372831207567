import React, { useState, useCallback } from "react";

import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

import { REACT_APP_GOOGLE_API_KEY } from "../../config/client_const";

const containerStyle = {
  width: "100vw",
  height: "400px",
};

const center = {
  lat: 45.2570833600973,
  lng: 19.847810093254306,
};

export const Map = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: REACT_APP_GOOGLE_API_KEY || "",
    language: "ru",
  });

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [zoomReset, setZoomReset] = useState(0);

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setTimeout(() => {
      setZoomReset(17);
    }, 1000);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoomReset}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker
        position={{
          lat: 45.2570833600973,
          lng: 19.847810093254306,
        }}
      />
    </GoogleMap>
  ) : (
    <></>
  );
};
