import { createSlice } from "@reduxjs/toolkit";
import { Service, ServiceItem } from '../../entities/ServiceItem/ServiceItem';

export interface initState {
  items: Service[];
}

const initialState: initState = {
  items: [
    {
      id: 1,
      title: "Услуги переводчика",
      description: `Предоставление профессионального русско-сербского переводчика для сопровождения в органы власти, банки и на любые другие форматы встреч.
      К каждому вашему запросу мы формируем конкретное предложение, так как пожелания и требования к работе всегда индивидуальны (цена услуги за час).`,
      price: 20,
      descrptionOptions: [],
      cartCount: 0,
    },
    {
      id: 2,
      title: "Перевод документов судебным переводчиком",
      description: "Перевод документов на сербский язык для обращения в государственные органы для получения услуг. Специалистом учитываются все требования юридического перевода, письменные документы подписываются и заверяются фирменной печатью (цена услуги за страницу).",
      price: 14,
      descrptionOptions: [],
      cartCount: 0,
    },
    {
      id: 3,
      title: "Встреча в аэропорту и трансфер до адреса в Новом Саду",
      description:
        "В аэропорту Белграда вас встретит русскоговорящий водитель с табличкой, осуществит трансфер на комфортабельном автомобиле до назначенного адреса, а также предоставит предоплаченную сим-карту местной мобильной связи.",
      price: 80,
      descrptionOptions: [
        {
          text: "До 4 чел. + багаж - 80€",
          additionalText: "",
        },
        {
          text: "До 6 чел. + багаж - 120€",
          additionalText: "",
        },
      ],
      cartCount: 0,
    },
    {
      id: 4,
      title: "Аренда квартиры",
      description:
        "Берем на себя подбор, поиск и оплату жилья посуточно в районах Банатић, Роткварија и Петроварадин города Нови Сад. Помимо этого предоставляем трансфер до квартиры и «Белый картон» —  документ о временной регистрации по месту пребывания в Сербии. ",
      price: 30,
      descrptionOptions: [
        {
          text: "Студия - 30€",
          additionalText: "",
        },
        {
          text: "1комн. - 50€",
          additionalText: "",
        },
        {
          text: "2комн. - 55€",
          additionalText: "",
        },
        {
          text: "3комн. - 65€",
          additionalText: "",
        },
      ],
      cartCount: 0,
    },
    {
      id: 6,
      title: "Открытие DOO",
      description: `Полный пакет услуг для открытия и регистрации юридического лица: предварительное консультирование, сопровождение визитов к нотариусу, подача и получение документов, постановка на учет в налоговую, полное адвокатское сопровождение до получения решения.`,
      price: 1000,
      descrptionOptions: [
        {
          text: "Необходимые сборы нотариата и госорганов",
          additionalText: "",
        },
      ],
      cartCount: 0,
    },
    {
      id: 7,
      title: "Открытие ИП",
      description: `Полный пакет услуг для открытия и регистрации индивидуального предпринимательства: предварительное консультирование, сопровождение визитов к нотариусу, подача и получение документов, постановка на учет в налоговую, полное адвокатское сопровождение до получения решения.`,
      price: 250,
      descrptionOptions: [
        {
          text: "Необходимые сборы нотариата и госорганов",
          additionalText: "",
        },
      ],
      cartCount: 0,
    },
    {
      id: 8,
      title: "Оформление ВНЖ",
      description: `Сопровождение при подаче вида на жительство: консультирование по формам, подача и прием документов, сопровождение визитов к нотариусу.`,
      price: 300,
      descrptionOptions: [
        {
          text: "Необходимые сборы нотариата и госорганов",
          additionalText: "",
        },
      ],
      cartCount: 0,
    },
    {
      id: 9,
      title: "Оформление разрешения на работу",
      description: "Иностранный гражданин может заниматься трудовой деятельностью только при наличии у него разрешения на работу, который выдается в соответствии с Законом о трудоустройстве иностранцев. Мы сопровождаем вас при прохождении всех этапов для получения разрешения, консультируем и помогаем с подачей необходимой документации.",
      price: 300,
      descrptionOptions: [],
      cartCount: 0,
    },
    {
      id: 10,
      title: "Сопровождение открытия р/с в банке",
      description: "Предлагаем полное сопровождение при открытии расчетного счета в банке. В услугу входит: консультирование со специалистом, предоставление списка необходимых документов, обеспечение переводчиком, сопровождение визитов в банк.",
      price: 100,
      descrptionOptions: [],
      cartCount: 0,
    },
    {
      id: 11,
      title: "Услуга сопровождения покупки квартиры",
      description:
        "Сопровождаем вас во время процесса приобретения жилья, а именно: предоставляем консультации от агентства недвижимости, услуги переводчика и риелтора. Организуем совместное посещение объектов недвижимости и обсуждение условий покупки, а также предварительное оформление документов под сделку. При необходимости обеспечиваем свидетелей во время подписания договора купли-продажи. Заказчиком дополнительно оплачиваются налоговые сборы и свидетели.",
      price: 500,
      descrptionOptions: [],
      cartCount: 0,
    },
    {
      id: 12,
      title: "Услуга переезд «под ключ» ИП",
      description:
        "Полностью берем на себя оформление всех необходимых документов для ИП при вашей перелокации. Оказываем квалифицированную помощь и сопровождение, а именно: предварительное консультирование и консультирование на всех этапах встреча в аэропорту и трансфер, предоставление краткосрочной аренды жилья с «белым картоном», открытие ИП и расчетного счета, получение ВНЖ и разрешения на работу.",
      price: 2000,
      descrptionOptions: [
        {
          text: "Необходимые сборы",
          additionalText: "",
        },
        {
          text: "Члены семьи по воссоединению семьи (только супруга и несовершеннолетние дети) + 300€/чел.",
          additionalText: "",
        },
      ],
      cartCount: 0,
    },
    {
      id: 13,
      title: "Услуга переезд «под ключ» DOO",
      description:
        "Возлагаем на себя решение документационных вопросов во время переезда вашей юридической фирмы. Делаем все, чтобы вы смогли продолжить свою деятельность на территории Сербии. В услугу входит: предварительное консультирование и консультирование на всех этапах встреча в аэропорту и трансфер, предоставление краткосрочной аренды жилья с «белым картоном», открытие или регистрация юридической фирмы, а также открытие расчетного счета, получение ВНЖ и разрешения на работу.",
      price: 2300,
      descrptionOptions: [
        {
          text: "Необходимые сборы",
          additionalText: "",
        },
        {
          text: "Члены семьи по воссоединению семьи (только супруга и несовершеннолетние дети) + 300€/чел.",
          additionalText: "",
        },
      ],
      cartCount: 0,
    },
    {
      id: 14,
      title: "Услуга переезд «под ключ» MAХ",
      description:
        "Помощь в переезде на территорию Сербии с максимальным комфортом и разрешением первостепенных задач. Вас ждет предварительное консультирование и консультирование на всех этапах, а также: встреча в аэропорту и трансфер, предоставление краткосрочной аренды жилья с «белым картоном», получение ВНЖ и разрешения на работу, возможность трудоустройства.",
      price: 3000,
      descrptionOptions: [
        {
          text: "Необходимые сборы",
          additionalText: "",
        },
        {
          text: "Члены семьи по воссоединению семьи (только супруга и несовершеннолетние дети) + 300€/чел.",
          additionalText: "",
        },
      ],
      cartCount: 0,
    },
    {
      id: 15,
      title: "Услуга трансфер из РФ",
      description:
        "Разработка и согласование вашего перемещения из страны. Вам не придется столкнуться с проблемой подбора маршрута, аренды жилья и вызовом авто, ведь мы сделаем это за вас: проконсультируем и учтем ваши пожелания, найдем и оплатим необходимые билеты, забронируем жилье, встретим в аэропорту и довезем до места проживания.",
      price: 300,
      descrptionOptions: [
        {
          text: "Стоимость билетов, гостиниц и трансферов по маршруту.",
          additionalText: "",
        },
      ],
      cartCount: 0,
    },
    {
      id: 16,
      title: "Оформление нотариальной доверенности для Сербии",
      description:
        "Помощь и сопровождение в получении нотариально заверенной доверенности. Если у вас нет времени или возможности подготовить документы, продать квартиру или совершить другое юридическое действие - поможет доверенность. Документ дает право доверенному лицу совершать от имени доверителя любые действия на территории Сербии.",
      price: 100,
      descrptionOptions: [],
      cartCount: 0,
    },
    {
      id: 17,
      title: "Оформление нотариальной доверенности для РФ",
      description: "Помощь и сопровождение в получении нотариально заверенной доверенности. Если у вас нет времени или возможности подготовить документы, продать квартиру или совершить другое юридическое действие - поможет доверенность. Документ дает право доверенному лицу совершать от имени доверителя любые действия на территории Российской Федерации.",
      price: 300,
      descrptionOptions: [
        {
          text: "без апостиля (3-5 дней) - 300€",
          additionalText: "",
        },
        {
          text: "с апостилем (10-12 дней) - 500€",
          additionalText: "",
        },
      ],
      cartCount: 0,
    },
    {
      id: 18,
      title: "Легализация учебных документов",
      description:
        "Придание диплому, аттестату или другому учебному документу юридической силы на территории Сербии для возможности продолжения обучения и трудоустройства. Предоставляем переводчика, необходимый список документов и специализированную организацию. Срок оказываемой услуги — до 90 дней.",
      price: 200,
      descrptionOptions: [],
      cartCount: 0,
    },
  ],
};

const servicesBucketSlice = createSlice({
  name: "servicesBucket",
  initialState,
  reducers: {
    fillBucket: (state, action) => {
      state.items = action.payload;
    },
    increaseCartCount: (state, action) => {
      state.items = state.items.map(item => {
        if (item.id === action.payload.id) {
          item.cartCount++;
        }
        return item;
      });
    },
    decreaseCartCount: (state, action) => {
      state.items = state.items.map(item => {
        if (item.id === action.payload.id) {
          item.cartCount--;
        }
        return item;
      });
    },
    setItemsToZero: state => {
      state.items = state.items.map(item => {
        item.cartCount = 0;
        return item;
      });
    }
  }
});

const { actions, reducer } = servicesBucketSlice;
const { fillBucket, increaseCartCount, decreaseCartCount, setItemsToZero } = actions;
export { fillBucket, increaseCartCount, decreaseCartCount, setItemsToZero };
export const selectBucket = (state: any) => state.servicesBucket;
export default reducer;