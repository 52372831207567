import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";

import { SpringGrid } from "../SpringGrid/SpringGrid";
import cs from "classnames";

import { addToBasket, removeFromBasket } from '../../redux/slices/basketSlice';
import { increaseCartCount, decreaseCartCount } from '../../redux/slices/servicesBucketSlice';
import { Service } from "../../entities/ServiceItem/ServiceItem";
import useWindowSize from "../../hooks/useWindowSize";

import arrow from "../../static/arrow.svg";

import stylesServices from './ServicesList.module.css';

export const ServicesList = (props: { services: Service[] }) => {
  const [windowWidth] = useWindowSize();
  const { services } = props;
  const dispatch = useDispatch();
  const [servicesStyles, setServicesStyles] = useState({});

  useEffect(() => {
    setTimeout(() => {
      setServicesStyles({opacity: 1})
    }, 700);
  }, [])
  
  function addToCart(service: Service) {
    dispatch(increaseCartCount(service));
    dispatch(addToBasket(service));
  }

  function removeFromCart (service: Service) {
    dispatch(decreaseCartCount(service)); 
    dispatch(removeFromBasket(service));
  };

  return (
    <>
      {/* @ts-ignore */}
      <SpringGrid className={stylesServices.services} style={servicesStyles}>
        {services.map((service) => (
          //@ts-ignore
          <div id={service.id} power_efficient="true" className={stylesServices.service}>
            {/* @ts-ignore */}
            <div content_opened="true" className={stylesServices.serviceContentOpened}>
              {/* @ts-ignore */}
              <button close_button="true" className={stylesServices.serviceCloseButton}>
                <img src={arrow} alt="arrow" className={stylesServices.serviceCloseIcon} />
              </button>
              {/* @ts-ignore */}
              <div close_button="true" className={stylesServices.serviceTopContent}>
                <div className={stylesServices.serviceTitleContainer}>
                  <h2 className={stylesServices.serviceTitle}>{service.title}</h2>
                </div>

                {
                  windowWidth > 800 ? (
                    <div className={stylesServices.servicePrice}>
                      <h2 className={stylesServices.servicePriceText}>от {service.price}€</h2>
                      {service.cartCount > 0 ? (
                        <div className={stylesServices.serviceBuyButtonWrapper}>
                          <button className={stylesServices.serviceBuyButton} onClick={() => removeFromCart(service)}>-</button>
                          <p className={stylesServices.serviceCartCount}>{service.cartCount}</p>
                          <button className={stylesServices.serviceBuyButton} onClick={() => addToCart(service)}>+</button>
                        </div>
                      ) : (
                        <button className={stylesServices.serviceBuyButton} onClick={() => addToCart(service)}>+</button>
                      )}
                      {/* <button className={stylesServices.serviceBuyButton}>+</button> */}
                    </div>
                  ) : <></>
                }
              </div>
              <div className={stylesServices.serviceBottomContent}>
                <div className={stylesServices.serviceBottomLeftContent}>
                  {service.descrptionOptions.map(
                    (option: { text: string; additionalText: string }) => {
                      return (
                        <div className={stylesServices.serviceOption}>
                          <div className={stylesServices.serviceOptionTextWrapper}>
                            <span className={stylesServices.servicePlusIcon}>+</span>
                            <h3 className={stylesServices.serviceOptionText}>
                              {option.text}
                              {option.additionalText ? (
                                <>
                                  <br />
                                  <span className={stylesServices.serviceAdditionalText}>
                                    {option.additionalText}
                                  </span>
                                </>
                              ) : null}
                            </h3>
                          </div>
                          {/* option.additionalText &&{" "} */}
                          {/* <p className={stylesServices.serviceAdditionalText}>{option.additionalText}</p> */}
                        </div>
                      );
                    }
                  )}
                </div>
                <div className={stylesServices.serviceBottomRightContent}>
                  <p className={stylesServices.serviceDescription}>{service.description}</p>
                </div>
              </div>
                {
                  windowWidth <= 800 ? (
                    <div className={cs(stylesServices.servicePrice, stylesServices.servicePriceOpened)}>
                      <h2 className={stylesServices.servicePriceText}>от {service.price}€</h2>
                      {service.cartCount > 0 ? (
                        <div className={stylesServices.serviceBuyButtonWrapper}>
                          <button className={stylesServices.serviceBuyButton} onClick={() => removeFromCart(service)}>-</button>
                          <p className={stylesServices.serviceCartCount}>{service.cartCount}</p>
                          <button className={stylesServices.serviceBuyButton} onClick={() => addToCart(service)}>+</button>
                        </div>
                      ) : (
                        <button className={stylesServices.serviceBuyButton} onClick={() => addToCart(service)}>+</button>
                      )}
                      {/* <button className={stylesServices.serviceBuyButton}>+</button> */}
                    </div>
                  ) : <></>
                }
            </div>

            {/* @ts-ignore */}
            <div content_closed="true" className={stylesServices.serviceContentClosed}>
              {/* @ts-ignore */}
              <button open_button="true" className={stylesServices.serviceOpenButton}>
                <img src={arrow} alt="arrow" />
              </button>
              {/* @ts-ignore */}
              <div open_button="true" className={stylesServices.serviceTitleContainer}>
                <h2 className={stylesServices.serviceTitle}>{service.title}</h2>
              </div>

              <div className={stylesServices.servicePrice}>
                <h2 className={stylesServices.servicePriceText}>от {service.price}€</h2>
                {service.cartCount > 0 ? (
                  <div className={stylesServices.serviceBuyButtonWrapper}>
                    <button className={stylesServices.serviceBuyButton} onClick={() => removeFromCart(service)}>-</button>
                    <p className={stylesServices.serviceCartCount}>{service.cartCount}</p>
                    <button className={stylesServices.serviceBuyButton} onClick={() => addToCart(service)}>+</button>
                  </div>
                ) : (
                  <button className={stylesServices.serviceBuyButton} onClick={() => addToCart(service)}>+</button>
                )}
                {/* <button className={stylesServices.serviceBuyButton}>+</button> */}
              </div>
            </div>
          </div>
        ))}
      </SpringGrid>
    </>
  );
};