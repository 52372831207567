import React, {
  useState,
  useRef,
  createRef,
  useEffect,
  useCallback,
} from "react";
import "./SpringGrid.css";

import classNames from "classnames";
import { useSpring, animated } from "react-spring";

import SGridBlock, { SGridBlockOpened, SGridBlockClosed } from "./SGrid__Block.js";

export function SpringGrid({
  handleStateChange,
  className,
  children,
  cardWidthOpen,
  cardWidthClosed,
  ...otherProps
}) {
  const [gridHeight, setGridHeight] = useState(null);
  const gridReferenceRef = useRef({ current: { clientHeight: 0 } });
  const [visibleCardsParams, setVisibleCardsParams] = useState(
    children && children.map
      ? children.map(() => {
          return {
            animate: "css",
            bounds: { offsetTop: 0, offsetLeft: 0, width: 0, height: 0 },
            opened: null,
            animationInProcess: false,
          };
        })
      : children && [
          {
            animate: "css",
            bounds: { offsetTop: 0, offsetLeft: 0, width: 0, height: 0 },
            opened: null,
            animationInProcess: false,
          },
        ]
  );
  const [refCardsParams, setRefCardsParams] = useState(
    children && children.map
      ? children.map(() => {
          return { opened: false };
        })
      : children && [
          {
            opened: false,
          },
        ]
  );
  const [manipulatedCard, setManipulatedCard] = useState();
  const [cardsRefs] = useState(() => {
    let refs = [];
    if (children) {
      if (children.forEach) {
        children.forEach(() => {
          refs.push(createRef([]));
        });
      } else {
        refs.push(createRef([]));
      }
      return refs;
    }
  });

  // const [cardsRefs] = useMemo(() => {
  //   let refs = [];
  //   if (children) {
  //     if (children.forEach) {
  //       children.forEach(() => {
  //         refs.push(createRef([]));
  //       });
  //     } else {
  //       refs.push(createRef([]));
  //     }
  //     console.log(refs);
  //     return refs;
  //   }
  // }, [children])

  const location = useRef({ current: window.location });

  const resizeVisibleCards = useCallback(() => {
    children && children.forEach
      ? children.forEach((child, id) => {
          let cardRelativePosition, throttleTime;
          if (id >= manipulatedCard) {
            cardRelativePosition = id - manipulatedCard;
            throttleTime = cardRelativePosition * 50;
          } else {
            throttleTime = 0;
          }

          if (child.props.power_efficient) {
            let powerEfficientMode = true;
            cardsRefs[id].current.measure(powerEfficientMode, throttleTime);
          } else {
            let powerEfficientMode = false;
            cardsRefs[id].current.measure(powerEfficientMode, throttleTime);
          }
        })
      : children &&
        cardsRefs[0].current.measure(children.props.power_efficient, 0);
    setGridHeight(gridReferenceRef.current.clientHeight);
  }, [children, manipulatedCard, cardsRefs]);

  useEffect(() => {
    window.addEventListener("resize", resizeVisibleCards);

    return () => {
      window.removeEventListener("resize", resizeVisibleCards);
    };
  }, [location.current, resizeVisibleCards]);

  function handleVisibleCardClick(cardId) {
    let refCardsParamsNew = Array.from(refCardsParams);
    refCardsParamsNew[cardId].opened = !refCardsParamsNew[cardId].opened;

    // if (refCardsParamsNew[cardId].opened) {
    //   visibleCardsParams.forEach((card, id) => {
    //     checkCardInViewport(id, card.bounds);
    //   })
    // }

    setRefCardsParams(refCardsParamsNew);
  }

  useEffect(() => {
    // setTimeout(() => {
    resizeVisibleCards();
    // }, 300);
  }, [refCardsParams, cardsRefs, children, resizeVisibleCards]);

  useEffect(() => {
    if (gridReferenceRef.current.clientHeight) {
      setGridHeight(gridReferenceRef.current.clientHeight);
    }
  }, [gridReferenceRef.current.clientHeight]);

  function handleCardAnimationStatus(cardId, animationInProcess) {
    let visibleCardsParamsNew = Array.from(visibleCardsParams);
    visibleCardsParamsNew[cardId].animationInProcess = animationInProcess;

    setVisibleCardsParams(visibleCardsParamsNew);
  }

  // function checkCardInViewport(cardId, bounds) {
  //   function absoluteOffsetTop(element) {
  //     let top = 0;

  //     do {
  //       top += element.offsetTop || 0;
  //       element = element.offsetParent;
  //     } while (element);

  //     return top;
  //   }

  //   let animateCard = () => {
  //     let windowHeight = document.getElementById("app").offsetHeight;
  //     let windowScrollTop = document.getElementById("app").scrollTop;
  //     let cardScrollTop =
  //       absoluteOffsetTop(gridReferenceRef.current) + bounds.offsetTop;
  //     let cardScrollBot = cardScrollTop + bounds.height;
  //     let scrollTopDifference = cardScrollBot - windowScrollTop;
  //     let scrollBotDifference = cardScrollTop - windowScrollTop;
  //     if (
  //       (windowHeight > scrollTopDifference - 50 ||
  //         windowHeight > scrollBotDifference - 50) &&
  //       (scrollTopDifference + 50 > 0 || scrollBotDifference + 50 > 0)
  //     ) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   };

  //   let visibleCardsParamsNew = Array.from(visibleCardsParams);
  //   visibleCardsParamsNew[cardId].animate = animateCard();
  //   setVisibleCardsParams(visibleCardsParamsNew);
  // }

  function handleRefCardState(cardId, opened, bounds) {
    //useMemo?? useCallback??
    // if (manipulatedCard && !refCardsParams[manipulatedCard].opened) {
    //   checkCardInViewport(cardId, bounds);
    // }

    let visibleCardsParamsNew = Array.from(visibleCardsParams);
    let boundsNew = Object.create(visibleCardsParamsNew[cardId].bounds);

    for (const param in bounds) {
      boundsNew[param] = bounds[param];
    }
    visibleCardsParamsNew[cardId].bounds = boundsNew;

    // visibleCardsParamsNew[cardId].bounds = bounds;

    visibleCardsParamsNew[cardId].opened = opened;

    setVisibleCardsParams(visibleCardsParamsNew);

    handleStateChange && handleStateChange(opened);
  }

  const gridAnimate = useSpring(
    gridHeight
      ? {
          height: gridHeight,
        }
      : false
  );

  function recursiveEnrichChild(child) {
    let propsKeys = Object.keys(
      child.props
    );
    let props = {};
    propsKeys.forEach((propKey) => {
      if (propKey === "children") {
        if (typeof child.props.children !== "object") {
          props.children = Object.values(child.props.children).map((recursiveChild) => recursiveEnrichChild(recursiveChild));
        } else {
          props.children = "";
        }
      }
      props[propKey] = child.props[propKey];
    });

    // if (child.props.children) {
    //   props.children = child.props.children.map((child) => recursiveEnrichChild(child));
    // }

    let childElement = React.createElement(
      child.type,
      props,
      child.props.children
    );

    // console.log(childElement);

    return childElement;
  }

  return (
    <div
      onLoad={resizeVisibleCards}
      className={classNames("spring-grid", className)}
      {...otherProps}
    >
      <animated.div style={gridAnimate} className="spring-grid__visible">
        {children && children.map
          ? children.map((child, id) => {
              return (
                <SGridBlock
                  className={classNames(child.props.className)}
                  key={"cardVisible" + id}
                  cardId={id}
                  visibleCardOpened={refCardsParams[id].opened}
                  animate={visibleCardsParams[id]}
                  handleCardAnimationStatus={handleCardAnimationStatus}
                  {...child.props}
                >
                  {child.props.children &&
                    child.props.children.length > 1 &&
                    child.props.children.map((cardContent, cardContentId) => {
                      if (cardContent.props.content_opened) {
                        return (
                          <SGridBlockOpened
                            key={"cardContentOpened" + cardContentId}
                            show={refCardsParams[id].opened}
                            onClick={() => {
                              setManipulatedCard(id);
                              handleVisibleCardClick(id);
                            }}
                            animationInProcess={
                              visibleCardsParams[id].animationInProcess
                            }
                            powerEfficientMode={child.props.power_efficient}
                            animationMode={visibleCardsParams[id].animate}
                            {...cardContent.props}
                          >
                            {/* {cardContent} */}
                            <div
                              content_opened="true"
                              className={cardContent.props.className}
                            >
                              {cardContent.props.children.length > 1 &&
                              cardContent.props.children.map
                                ? cardContent.props.children.map(
                                    (child, key) => {
                                      if (child.props.close_button) {
                                        let propsKeys = Object.keys(
                                          child.props
                                        );
                                        let props = {};
                                        propsKeys.forEach((propKey) => {
                                          props[propKey] = child.props[propKey];
                                        });

                                        delete props.close_button;

                                        props.onClick = () => {
                                          setManipulatedCard(id);
                                          handleVisibleCardClick(id);
                                        };
                                        props["key"] = key;

                                        let closeButton = React.createElement(
                                          child.type,
                                          props,
                                          child.props.children
                                        );

                                        return closeButton;
                                      } else {
                                        // return child;
                                        // let propsKeys = Object.keys(
                                        //   child.props
                                        // );
                                        // let props = {};
                                        // propsKeys.forEach((propKey) => {
                                        //   props[propKey] = child.props[propKey];
                                        // });

                                        // let childElement = React.createElement(
                                        //   child.type,
                                        //   props,
                                        //   child.props.children
                                        // );

                                        const childElement = recursiveEnrichChild(child)

                                        return childElement;
                                      }
                                    }
                                  )
                                : cardContent.props.children &&
                                  cardContent.props.children}
                            </div>
                          </SGridBlockOpened>
                        );
                      }
                      if (cardContent.props.content_closed) {
                        return (
                          <SGridBlockClosed
                            key={"cardContentClosed" + cardContentId}
                            show={!refCardsParams[id].opened}
                            onClick={() => {
                              if (!child.props.always_closed) {
                                setManipulatedCard(id);
                                handleVisibleCardClick(id);
                              }
                            }}
                            animationInProcess={
                              visibleCardsParams[id].animationInProcess
                            }
                            powerEfficientMode={child.props.power_efficient}
                            animationMode={visibleCardsParams[id].animate}
                            {...cardContent.props}
                          >
                            {/* {cardContent} */}
                            <div
                              content_opened="false"
                              className={cardContent.props.className}
                            >
                              {cardContent.props.children.length > 1 &&
                              cardContent.props.children.map
                                ? cardContent.props.children.map(
                                    (child, key) => {
                                      if (child.props.open_button) {
                                        let propsKeys = Object.keys(
                                          child.props
                                        );
                                        let props = {};
                                        propsKeys.forEach((propKey) => {
                                          props[propKey] = child.props[propKey];
                                        });

                                        delete props.open_button;

                                        props.onClick = () => {
                                          setManipulatedCard(id);
                                          handleVisibleCardClick(id);
                                        };
                                        props["key"] = key;

                                        let openButton = React.createElement(
                                          child.type,
                                          props,
                                          child.props.children
                                        );

                                        return openButton;
                                      } else {
                                        // return child;
                                        // console.log(child);
                                        // let propsKeys = Object.keys(
                                        //   child.props
                                        // );
                                        // console.log(child.props);
                                        // let props = {};
                                        // propsKeys.forEach((propKey) => {
                                        //   props[propKey] = child.props[propKey];
                                        // });

                                        // let childElement = React.createElement(
                                        //   child.type,
                                        //   props,
                                        //   child.props.children
                                        // );
                                        const childElement = recursiveEnrichChild(child);
                                        return childElement;
                                      }
                                    }
                                  )
                                : cardContent.props.children &&
                                  cardContent.props.children}
                            </div>
                          </SGridBlockClosed>
                        );
                      }

                      return {};
                    })}
                </SGridBlock>
              );
            })
          : children && (
              <SGridBlock
                className={classNames(children.props.className)}
                key={"cardVisible" + 0}
                cardId={0}
                visibleCardOpened={refCardsParams[0].opened}
                animate={visibleCardsParams[0]}
                handleCardAnimationStatus={handleCardAnimationStatus}
              >
                {children.props.children &&
                  children.props.children.length > 1 &&
                  children.props.children.map((cardContent, cardContentId) => {
                    if (cardContent.props.content_opened) {
                      return (
                        <SGridBlockOpened
                          key={"cardContentOpened" + cardContentId}
                          show={refCardsParams[0].opened}
                          onClick={() => {
                            setManipulatedCard(0);
                            handleVisibleCardClick(0);
                          }}
                          animationInProcess={
                            visibleCardsParams[0].animationInProcess
                          }
                          powerEfficientMode={children.props.power_efficient}
                          animationMode={visibleCardsParams[0].animate}
                        >
                          <div
                            content_opened="true"
                            className={cardContent.props.className}
                          >
                            {cardContent.props.children.length > 1 &&
                              cardContent.props.children.map(
                                (children, key) => {
                                  if (children.props.close_button) {
                                    let propsKeys = Object.keys(children.props);
                                    let props = {};
                                    propsKeys.forEach((propKey) => {
                                      props[propKey] = children.props[propKey];
                                    });

                                    delete props.close_button;

                                    props.onClick = () => {
                                      setManipulatedCard(0);
                                      handleVisibleCardClick(0);
                                    };
                                    props["key"] = key;

                                    let closeButton = React.createElement(
                                      children.type,
                                      props,
                                      children.props.children
                                    );

                                    return closeButton;
                                  } else {
                                    return children;
                                  }
                                }
                              )}
                          </div>
                        </SGridBlockOpened>
                      );
                    }
                    if (cardContent.props.content_closed) {
                      return (
                        <SGridBlockClosed
                          key={"cardContentClosed" + cardContentId}
                          show={!refCardsParams[0].opened}
                          onClick={() => {
                            setManipulatedCard(0);
                            handleVisibleCardClick(0);
                          }}
                          animationInProcess={
                            visibleCardsParams[0].animationInProcess
                          }
                          powerEfficientMode={children.props.power_efficient}
                          animationMode={visibleCardsParams[0].animate}
                        >
                          {cardContent}
                        </SGridBlockClosed>
                      );
                    }

                    return {};
                  })}
              </SGridBlock>
            )}
      </animated.div>

      <div ref={gridReferenceRef} className="spring-grid__reference">
        {children && children.map
          ? children.map((child, id) => {
              return (
                <SGridBlock
                  key={"cardRef" + id}
                  ref={cardsRefs[id]}
                  refCardOpened={refCardsParams[id].opened}
                  cardId={id}
                  handleRefCardState={handleRefCardState}
                  refBlock
                  {...child.props}
                >
                  {child.props.children &&
                    child.props.children.length > 1 &&
                    child.props.children.map((cardContent, cardContentId) => {
                      if (cardContent.props.content_opened) {
                        return (
                          <SGridBlockOpened
                            refCard
                            key={"cardContentOpened" + cardContentId}
                            show={refCardsParams[id].opened}
                            className={cardContent.props.className}
                            {...cardContent.props}
                          >
                            {/* {cardContent} */}
                            <div
                              content_opened="true"
                              className={cardContent.props.className}
                            >
                              {cardContent.props.children.length > 1 &&
                              cardContent.props.children.map
                                ? cardContent.props.children.map(
                                    (child, key) => {
                                      if (child.props.close_button) {
                                        let propsKeys = Object.keys(
                                          child.props
                                        );
                                        let props = {};
                                        propsKeys.forEach((propKey) => {
                                          props[propKey] = child.props[propKey];
                                        });

                                        delete props.close_button;

                                        props.onClick = () => {
                                          setManipulatedCard(id);
                                          handleVisibleCardClick(id);
                                        };
                                        props["key"] = key;

                                        let closeButton = React.createElement(
                                          child.type,
                                          props,
                                          child.props.children
                                        );

                                        return closeButton;
                                      } else {
                                        // return child;
                                        // let propsKeys = Object.keys(
                                        //   child.props
                                        // );
                                        // let props = {};
                                        // propsKeys.forEach((propKey) => {
                                        //   props[propKey] = child.props[propKey];
                                        // });

                                        // let childElement = React.createElement(
                                        //   child.type,
                                        //   props,
                                        //   child.props.children
                                        // );

                                        const childElement = recursiveEnrichChild(child)

                                        return childElement;
                                      }
                                    }
                                  )
                                : cardContent.props.children &&
                                  cardContent.props.children}
                            </div>
                          </SGridBlockOpened>
                        );
                      }
                      if (cardContent.props.content_closed) {
                        return (
                          <SGridBlockClosed
                            refCard
                            key={"cardContentClosed" + cardContentId}
                            show={!refCardsParams[id].opened}
                            {...cardContent.props}
                          >
                            {/* {cardContent} */}
                            <div
                              content_opened="false"
                              className={cardContent.props.className}
                            >
                              {
                                recursiveEnrichChild(cardContent)
                              }
                            </div>
                          </SGridBlockClosed>
                        );
                      }

                      return {};
                    })}
                </SGridBlock>
              );
            })
          : children && (
              <SGridBlock
                key={"cardRef" + 0}
                ref={cardsRefs[0]}
                refCardOpened={refCardsParams[0].opened}
                cardId={0}
                handleRefCardState={handleRefCardState}
                refBlock
              >
                {children.props.children &&
                  children.props.children.length > 1 &&
                  children.props.children.map((cardContent, cardContentId) => {
                    if (cardContent.props.content_opened) {
                      return (
                        <SGridBlockOpened
                          refCard
                          key={"cardContentOpened" + cardContentId}
                          show={refCardsParams[0].opened}
                          className={cardContent.props.className}
                        >
                          {cardContent}
                        </SGridBlockOpened>
                      );
                    }
                    if (cardContent.props.content_closed) {
                      return (
                        <SGridBlockClosed
                          refCard
                          key={"cardContentClosed" + cardContentId}
                          show={!refCardsParams[0].opened}
                        >
                          {cardContent}
                        </SGridBlockClosed>
                      );
                    }

                    return {};
                  })}
              </SGridBlock>
            )}
      </div>
    </div>
  );
}
