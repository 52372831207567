import { createSlice } from "@reduxjs/toolkit";
import { Service } from '../../entities/ServiceItem/ServiceItem';

export interface initState {
  items: Service[];
  totalPrice: number;
}

const initialState: initState = {
  items: [],
  totalPrice: 0,
};

const basketSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    addToBasket: (state, action) => {
      const itemInBasket = state.items.find(item => item.id === action.payload.id);
      if (itemInBasket) {
        state.items[state.items.indexOf(itemInBasket)].cartCount++;
      } else {
        const newItemInBasket = { ...action.payload, cartCount: 1 };
        state.items = [...state.items, newItemInBasket];
      }
      state.totalPrice += action.payload.price;
    },
    removeFromBasket: (state, action) => {
      const itemIndex = state.items.findIndex(
        (basketItem: any) => basketItem.id === action.payload.id
      );
      let newBasket = [...state.items];

      if (itemIndex >= 0) {
        if (newBasket[itemIndex].cartCount === 1) {
          newBasket.splice(itemIndex, 1);
        } else {
          newBasket[itemIndex].cartCount--;
        }
        state.items = newBasket;
        state.totalPrice -= action.payload.price;
      } else {
        console.warn(
          `Can't remove product (id: ${action.payload.id}, title: ${action.payload.title}) as its not in basket!`
        );
      }
      
      state.items = newBasket;

      // console.log(state.items[0].id, action.payload.id);
      // const itemInBasket = state.items.find(item => 10 === action.payload.id);
      
      // console.log(itemInBasket);
      // if (itemInBasket && itemInBasket.cartCount > 1) {
      //   console.log(1);
      //   state.items[state.items.indexOf(itemInBasket)].cartCount--;
      // } else {
      //   console.log(2);
      //   const index = state.items.findIndex(
      //     (basketItem: any) => basketItem.id === action.payload.id
      //   );
      //   let newBasket = [...state.items];
  
      //   if (index >= 0) {
      //     newBasket.splice(index, 1);
      //     state.totalPrice -= state.items[action.payload.id].price;
      //   } else {
      //     console.warn(
      //       `Can't remove product (id: ${action.payload.id}) as its not in basket!`
      //     );
      //   }
  
      //   state.items = newBasket;
      // }

      // state.totalPrice -= action.payload.price;
    },
    emptyBasket: (state) => {
      state.items = [];
      state.totalPrice = 0;
    }
  }
});

const { actions, reducer } = basketSlice;
const { addToBasket, removeFromBasket, emptyBasket } = actions;
export { addToBasket, removeFromBasket, emptyBasket };
export const selectBasket = (state: any) => state.basket;
export default reducer;