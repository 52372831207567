import { Link } from "react-router-dom";
import logo from "../../static/logo.svg";
import iconFacebook from '../../static/icon_facebook.svg';
import iconInstagram from '../../static/icon_instagram.svg';
import iconTelegram from '../../static/icon_telegram.svg';
import iconWhatsapp from '../../static/icon_whatsapp.svg';

import stylesFooter from './Footer.module.css';

export const Footer = () => {
  return (
    <footer className={stylesFooter.footer}>
      <div className={stylesFooter.footerContainer}>
        <div className={stylesFooter.footerLeft}>
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className={stylesFooter.footerRight}>
          <p className={stylesFooter.footerText}>
            e-mail:{" "}
            <a href="mailto:srb4u.rs@gmail.com">srb4u.rs@gmail.com</a>
          </p>
          <p className={stylesFooter.footerText}>
            Тел: <a href="tel:+381637052957">+381 63 7052957</a>
          </p>
          <div className={stylesFooter.footerSocial}>
            <a href="https://t.me/srb4you/" target="_blank" rel="noreferrer">
              <img src={iconTelegram} className={stylesFooter.footerSocialIcon} alt="telegram" />
            </a>
            <a href="https://api.whatsapp.com/send?phone=381637052957" target="_blank" rel="noreferrer">
              <img src={iconWhatsapp} className={stylesFooter.footerSocialIcon} alt="whatsapp" />
            </a>
            <a href="https://www.facebook.com/sveuredu.rs/">
              <img src={iconFacebook} className={stylesFooter.footerSocialIcon} alt="facebook" />
            </a>
            <a href="https://www.instagram.com/sveuredu.rs/">
              <img src={iconInstagram} className={stylesFooter.footerSocialIcon} alt="instagram" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
