import { configureStore } from "@reduxjs/toolkit";
import servicesBucketSlice from "./slices/servicesBucketSlice";
import basketSlice from "./slices/basketSlice";

export const store = configureStore({
  reducer: {
    servicesBucket: servicesBucketSlice, 
    basket: basketSlice
  }
});
