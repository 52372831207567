import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import cs from 'classnames';

import useScrollPosition from "../../hooks/useScrollPosition";
import { selectBasket } from "../../redux/slices/basketSlice";
import { Service } from "../../entities/ServiceItem/ServiceItem";

import stylesHeader from "./Header.module.css";

import logo from "../../static/logo.svg";
import cart from "../../static/cart.svg";

export const Header = () => {
  const basket = useSelector(selectBasket);
  const totalItemsInBasket = basket.items.reduce(
    (acc: number, item: Service) => acc + item.cartCount,
    0
  );
  const scrollPosition = useScrollPosition();
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);

  useEffect(() => {
    if (scrollPosition > 0) {
      setIsHeaderFixed(true);
    } else {
      setIsHeaderFixed(false);
    }
  }, [scrollPosition]);

  return (
    <header className={cs(stylesHeader.header, (isHeaderFixed && stylesHeader.header_fixed))}>
      <nav className={stylesHeader.nav}>
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
        <Link to="/cart" className={stylesHeader.navLink}>
          <img src={cart} alt="cart" />
          {totalItemsInBasket > 0 && (
            <span className={stylesHeader.cartItemsCount}>
              {totalItemsInBasket}
            </span>
          )}
        </Link>
      </nav>
    </header>
  );
};
