import React from "react";
import { useSelector } from "react-redux";

import { selectBucket } from "../../redux/slices/servicesBucketSlice";

import { ServicesList } from '../../components/ServicesList/ServicesList';

import stylesMain from './Main.module.css';

import topBackground from "../../static/top-background.svg";

export const Main = () => {
  const services = useSelector(selectBucket);

  return (
    <main>
      <div className={stylesMain.topPart}>
        <img
          src={topBackground}
          alt="top-background"
          className={stylesMain.topPartBackground}
        />
        <div className={stylesMain.topPartContent}>
          <h1 className={stylesMain.topPartHeader}>
            <span className={stylesMain.topPartHeaderHighlighted}>SRB4U - </span>
            Сербия для Вас!
          </h1>
        </div>
      </div>
      <div className={stylesMain.content}>
        <ServicesList services={services.items} />
        {/* @ts-ignore */}
        {/* <SpringGrid className={services}>
          {services.map((service) => (
            <ServiceCard service={service} />
          ))}
        </SpringGrid> */}
      </div>
    </main>
  );
};
