import "./App.css";
import { useState, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import { Header, Footer, Map } from './components'
import { Main } from "./pages";
import { Cart } from "./pages";

function App() {
  const [ contentStyles, setContentStyles ] = useState({});

  useEffect(() => {
    setTimeout(() => {
      setContentStyles({ opacity: 1 });
    }, 1000);
  }, []);

  return (
    <div className="content" style={contentStyles}>
      <Header />
      <Routes>
        <Route path={"/"} element={<Main />} />
        <Route path={"/cart"} element={<Cart />} />
      </Routes>
      <Map />
      <Footer />
    </div>
  );
}

export default App;
